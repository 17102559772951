@media all {
  .Login {
    background-image: url("/src/assets/img/login/BG asset-Large –– 1170x658_v2.jpg");
    /* background: -webkit-image-set(
      url("/src/assets/img/login/frame-313315@2x.png") 2x,
      url("/src/assets/img/login/frame-313315@3x.png") 3x
    ); */
    background-color: var(--neutral-black);
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 22px 0 0 0;
  }

  .Login form {
    margin: 110px auto 110px auto;
    max-width: 554px;
    background-color: #fff;
    padding: 32px 32px 50px;
    border: solid 1px #dadada;
  }

  .Login form .Login-text {
    align-self: stretch;
    flex-grow: 0;
    font-family: AmazonEmberDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #16191f;
    margin: 48px 0;
  }

    .Login a {
        color: #4b66c8;
        text-decoration: underline;
        word-break: break-word;
        font-weight: 600;
    }

        .Login a:hover,
        .Login a:active,
        .Login a:focus {
            color: #3650B0;
            text-decoration: underline;
        }

        .Login a:focus {
            outline: 1.5px dashed currentColor;
        }

  .Login form input:focus {
    outline: 1.5px dashed #000;
    outline-offset: 1px;
  }

  .Login form button:focus {
    outline: 1.5px dashed #000;
    outline-offset: 1px;
  }

  .Reset-password form {
    margin: 160px auto 160px auto
  }
}
