body {
  margin: 0;
  font-family: AmazonEmberDisplay, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.loading #awsccc-sb-ux-c {
  visibility: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.skiptocontent__link {
    padding: 6px 10px;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
    color: #FFFFFF !important;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: transparent;
    -webkit-transition: top 1s ease-out, background 1s linear;
    transition: top 1s ease-out, background 1s linear;
    z-index: 100;
    
}
    a.skiptocontent__link:focus {
        position: absolute;
        left: 50%;
        top: 0;
        background: #061B86;
        outline: 0;
        -webkit-transition: top .1s ease-in, background .5s linear;
        transition: top .1s ease-in, background .5s linear;
    }
