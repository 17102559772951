@media all {
  .Navbar {
    padding: 0 !important;
  }

  .Nav .Nav-register {
    background-color: #22f49b;
    padding: 12px 32px !important;
    border: solid 1px rgba(0, 0, 0, 0);
    font-family: AmazonEmberDisplay;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #00007b !important;
    cursor: pointer;
  }

  .Nav .Nav-login {
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 32px !important;
    flex-grow: 0;
    font-family: AmazonEmberDisplay;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #fff !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .Nav .Nav-login:hover,
  .Nav .Nav-login:focus {
    text-decoration: none;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    color: #fff !important;
    border-bottom-color: #fff;
    border-image-slice: 1;
  }
}
