.Password-input input {
  width: 100%;
  padding-right: 36px !important;
}

.Password-input .show-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 9999;
  color: #272727;
}
