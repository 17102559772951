@media all {
  .Footer {
    min-height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* margin: 199px 0 0 3px; */
    padding: 18px 0;
    border-top-style: solid;
    border-top-width: 2px;
    border-image-source: linear-gradient(
      to right,
      #2cd266 0%,
      #48afe5 49%,
      #e50086 100%
    );
    border-image-slice: 1;
    font-family: AmazonEmberDisplay;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }

  .Footer .nav .nav-item:not(:last-child) {
    border-right: 1px solid #fff;
  }

  .Footer .nav .nav-item {
    padding: 0 12px;
  }

  .Footer .nav .nav-item:first-child {
    padding-left: 0;
  }

    .Footer .nav .nav-item .nav-link {
        height: 18px;
        flex-grow: 0;
        padding: 0.5px 0;
        font-family: AmazonEmberDisplay;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        text-decoration: underline; 
    }

  .Footer .nav .nav-item .nav-link:hover {
    text-decoration: underline;
  }
  .nav-link:focus {
      outline: 1.5px dashed currentColor;
  }
}

@media all and (max-width: 480px) {
  .Footer {
    flex-direction: column;
  }

  .Footer .Copyright {
    margin-top: 20px;
  }
}
