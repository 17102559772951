@media all {
    .Signup {
        background-image: url("/src/assets/img/login/BG asset-Large –– 1170x658_v2.jpg");
        background-color: var(--neutral-black);
        min-height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        padding: 22px 0 0 0;
    }

    .Signup-form {
        margin: 60px auto 60px auto;
        gap: 48px;
        padding: 32px;
        border: solid 1px #dadada;
        background-color: #fff;
        max-width: 711px;
    }


        .Signup-form a {
            color: #4b66c8;
            text-decoration: underline;
            word-break: break-word;
            font-weight: 600;
        }
            .Signup-form a:hover,
            .Signup-form a:active,
            .Signup-form a:focus {
                color: #3650B0;
                text-decoration: underline;
            }

        .Signup-form a:focus {
            outline: 1.5px dashed currentColor;
        }

  .Signup .Signup-info {
    margin-top: 32px;
    font-family: AmazonEmberDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #16191f;
  }

  .Signup .Signup-info h2 {
    letter-spacing: 0.2em;
    font-size: 16px;
    font-weight: bolder;
  }

  .Signup form input:focus {
    outline: 1.5px dashed #000;
    outline-offset: 1px;
  }

  .Signup form button:focus {
    outline: 1.5px dashed #000;
    outline-offset: 1px;
  }
}
