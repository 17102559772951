@media all {
  .Confirm-registration {
    margin: 160px auto 160px auto;
    padding: 32px;
    border: solid 1px #dadada;
    background-color: #fff;
    max-width: 711px;
  }

    .Confirm-registration a {
        color: #4b66c8;
        text-decoration: underline;
        word-break: break-word;
        font-weight: 600;
    }

        .Confirm-registration a:hover,
        .Confirm-registration a:active,
        .Confirm-registration a:focus {
            color: #3650B0;
            text-decoration: underline;
        }

  .Confirm-registration .Confirm-text {
    align-self: stretch;
    flex-grow: 0;
    font-family: AmazonEmberDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin: 16px 0;
  }

  .Confirm-registration .alert {
    font-family: AmazonEmberDisplay;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }
}
