@font-face {
  font-family: AmazonEmberDisplay;
  src: local(AmazonEmberDisplay-Light),
    url("/src/assets/font/AmazonEmberDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: AmazonEmberDisplay;
  src: local(AmazonEmberDisplay-Regular),
    url("/src/assets/font/AmazonEmberDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: AmazonEmberDisplay;
  src: local(AmazonEmberDisplay-Medium),
    url("/src/assets/font/AmazonEmberDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: AmazonEmberDisplay;
  src: local(AmazonEmberDisplay-Bold),
    url("/src/assets/font/AmazonEmberDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Poppins;
  src: local(Poppins-Light),
    url("/src/assets/font/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Poppins;
  src: local(Poppins-Regular),
    url("/src/assets/font/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

/* .App nav {
  margin-bottom: 60px;
} */

h1.title {
    font-family: AmazonEmberDisplay;
    font-size: 36px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #16191f;
    padding: 0 0 0 0;
    margin: 0;
}

.title {
  font-family: AmazonEmberDisplay;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #16191f;
  margin: 0;
}

.subtitle {
    color: rgb(104, 112, 120);
    font-family: AmazonEmberDisplay;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
}
.label-subtitle {
    padding: 0 0 0 8px;
    margin-bottom: 4px;
    color: rgb(104, 112, 120);
    font-family: AmazonEmberDisplay;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: normal;
    text-align: left;
}

.form-group:has(.label-subtitle) .form-label {
    margin-bottom: 0;
}

form .form-group {
  margin: 32px 0 0 0;
}

form .form-group .form-label,
form .form-group .form-check .form-check-label {
  padding: 0 0 0 8px;
  font-family: AmazonEmberDisplay;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #16191f;
  margin-bottom: 4px;
}

form .form-group .form-label span {
  color: #22f49b;
  width: 7px;
  height: 19px;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
}

form .form-group .form-label div,
.title div {
  color: rgb(104, 112, 120);
  font-family: AmazonEmberDisplay;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
}

form .form-group .form-control {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 8px;
  border: solid 1px #0069EB;
  border-radius: 0;
  background-color: #fff;
  font-family: AmazonEmberDisplay;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #16191f;
  box-shadow: none !important;
}

form .from-group .form-check .form-check-input {
}

form .form-group .form-control.is-invalid {
  border-color: #d13212;
}

form .form-group .form-control.is-valid {
  border-color: #138a08;
}

form .form-group .valid-feedback {
  display: block;
  color: #138a08;
}

form .form-group .invalid-feedback {
  display: block;
  color: #d13212;
}

form .form-group .valid-feedback,
form .form-group .invalid-feedback {
  font-family: AmazonEmberDisplay;
  margin-top: 6px;
}

form .form-group .valid-feedback svg,
form .form-group .invalid-feedback svg {
  margin-right: 10px;
}

form .btn {
  box-shadow: none !important;
}

form .btn-primary {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 32px;
  border: solid 1px #ff9900 !important;
  border-radius: 24px;
  background-color: #ff9900 !important;
  /* background-image: linear-gradient(
    to right,
    #9819ff 0%,
    #0143b4 16%,
    #25c1ff 32%,
    #71ffa1 50%,
    #25c1ff 66%,
    #0143b4 82%,
    #9819ff 100%
  ); */
  font-family: AmazonEmberDisplay;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #232f3e !important;
  /* transition: 0.5s; */
  /* background-size: 200% auto; */
}

form .btn-primary:focus,
form .btn-primary:hover {
  /* background-position: right center; */
  border: solid 1px #fcb750 !important;
  background-color: #fcb750 !important;
}

form .btn-outline-primary {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 32px;
  border: solid 2px #232f3e;
  border-radius: 24px;
  background-color: #fff;
  font-family: AmazonEmberDisplay;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #232f3e;
}

form .btn-outline-primary:hover,
form .btn-outline-primary:focus,
form .btn-outline-primary:active {
  color: #545b64 !important;
  border: solid 2px #545b64 !important;
  background-color: #fff !important;
}
form .btn-outline-primary:disabled {
  color: #545b64 !important;
}

form .btn-link {
  height: 48px;
  padding: 12px 32px;
  display: flex;
  /* color: #00007b; */
  color: #232f3e;
  font-family: AmazonEmberDisplay;
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.25px;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}

form .btn-link:hover,
form .btn-link:focus,
form .btn-link:active {
  /* color: #00007b; */
  color: #545b64;
  text-decoration: none;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #545b64;
  /* border-image-source: linear-gradient(
    to right,
    #2cd266 0%,
    #48afe5 49%,
    #e50086 100%
  ); */
  border-image-slice: 1;
}

.position-relative {
  position: relative;
}

.form-control.is-invalid,
.form-control.is-valid {
  background-image: none !important;
}

.alert.alert-danger {
  border-radius: 0;
  color: #d13212;
  font-family: AmazonEmberDisplay;
  font-weight: 400;
}

.alert.alert-danger button {
  color: #d13212;
}
