.Login .error {
  margin: 110px auto 110px auto;
  max-width: 554px;
  background-color: #fff;
  padding: 32px 32px 50px;
  border: solid 1px #dadada;
  position: relative;
}

.Login .error a {
  color: #4b66c8 !important;
  text-decoration: underline;
  word-break: break-word;
  font-weight: 600;
}